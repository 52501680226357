if (document.querySelectorAll(".mySwiper2T").length > 0) {
    var swiper = new Swiper(".mySwiperT", {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        direction: "vertical",
    });
    var swiper2 = new Swiper(".mySwiper2T", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });
    var swiperProduct = new Swiper("#productSwiper", {
        slidesPerGroup: 1,

        loop: false,
        loopFillGroupWithBlank: false,
        pagination: {
            el: "#productSwiper .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: "#productSwiper .swiper-button-next",
            prevEl: "#productSwiper .swiper-button-prev",
        },
    });
}
if (document.querySelectorAll("#mainSwiper").length > 0) {
    var swiper2 = new Swiper("#mainSwiper", {
        direction: 'horizontal',
        effect: "fade",
        lazy: true,
        // autoplay: {
        //     delay: 6000,
        //     speed: 1000,
        //     disableOnInteraction: false,
        // },
        loop: true,
        preventInteractionOnTransition: true,

        pagination: {
            el: "#mainSwiper .swiper-pagination",
            type: "fraction",
        },
        navigation: {
            nextEl: "#mainSwiper .swiper-button-next",
            prevEl: "#mainSwiper .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',

    });

    swiper2.on('slideChange', function(swiper) {
        console.log('slide changed');
        console.log(swiper);
    });
  
    swiper2.on('beforeSlideChangeStart', function(swiper) {
        console.log('slide changed2');
        console.log(swiper);

    });
    swiper2.on('slideChangeTransitionEnd', function(swiper) {
        console.log('slide changed22');
        console.log(swiper);
      
    });
    swiper2.on('activeIndexChange', function(swiper) {
        console.log('slide changed223');
        console.log(swiper);
     
    });

}